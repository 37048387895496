<template>
    <div id="app">
        <v-app>
            <div class="page">
                <div class="wrapped-content">
                    <img id="logo" src="./assets/logo.png" />
                    <v-card elevation="0" class="pa-4">
                        <router-view />
                    </v-card>
                    <footer class="text-center caption mt-3 px-3">
                        <a href="https://www.dpsg-muenster.de/info/impressum/" target="_blank" title="Impressum" class="legals pl-2"> Impressum </a>
                        &nbsp;
                        <a href="https://www.dpsg-muenster.de/info/datenschutzbestimmungen/" target="_blank" title="Datenschutz" class="legals"> Datenschutz </a>
                        <template v-if="window.thinkApp">
                            <br />
                            <span class="build">
                                {{ window.thinkApp.build }}
                            </span>
                        </template>
                    </footer>
                </div>
            </div>
            <notifications-snackbar />
        </v-app>
    </div>
</template>

<script>
import NotificationsSnackbar from "./components/NotificationsSnackbar.vue";

export default {
    name: "App",

    components: {
        NotificationsSnackbar,
    },

    data: () => ({
        window,
    }),
};
</script>
