import Vue from "vue";

const messageBus = new Vue();

const types = {
    info: {
        color: "info",
        icon: "fa-info",
    },
    success: {
        color: "success",
        icon: "fa-check",
    },
    error: {
        color: "error",
        icon: "fa-exclamation-triangle",
    },
};

export const sendMessage = (message, type = "info") => {
    const messageStatus = types[`${type}`] || types["info"];

    messageBus.$emit("message", {
        show: true,
        message,
        ...messageStatus,
    });
};

export default messageBus;
