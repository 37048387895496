<template>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :data-message="snackbar.message" bottom app multi-line>
        <v-icon dark class="mr-3" v-text="snackbar.icon" left />
        <span>{{ snackbar.message }}</span>
    </v-snackbar>
</template>

<script>
import messageBus from "../plugins/messageBus";

export default {
    name: "NotificationsSnackbar",

    data: () => ({
        snackbar: {
            show: false,
            message: "",
            color: "info",
            icon: "fa-info",
        },
    }),

    created() {
        messageBus.$on("message", message => (this.snackbar = message));
    },
};
</script>
