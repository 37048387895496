import axios from "axios";

const login = async (email, password) => {
    const response = await axios.post("/api/v1/auth/login", {
        email,
        password,
    });
    return response.data.data;
};

const activate = async (email, password, validationToken) => {
    const response = await axios.put("/api/v1/auth/activate", {
        email,
        password,
        validationToken,
    });
    return response.data.data;
};

const requestPasswordReset = async email => {
    await axios.post("/api/v1/password-reset", {
        email,
    });
};

const resetPassword = async (token, password) => {
    await axios.put("/api/v1/password-reset", {
        token,
        password,
    });
};

const updateProfile = async ({ tribe, email, currentPassword, newPassword, displayName, nickname }) => {
    await axios.put("/api/v1/user", {
        tribe,
        email,
        displayName,
        nickname,
        ...(currentPassword && { currentPassword }),
        ...(newPassword && { newPassword }),
    });
};

const getUser = async () => {
    const response = await axios.get("/api/v1/user");
    return response.data.data;
};

export default {
    login,
    activate,
    requestPasswordReset,
    resetPassword,
    updateProfile,
    getUser,
};
