import Vue from "vue";
import VueRouter from "vue-router";
import AppView from "../App.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: AppView,
        children: [
            {
                path: "/passwort-vergessen/:token?",
                name: "PasswordReset",
                props: true,
                component: () => import(/* webpackChunkName: "PasswordReset" */ "../views/PasswordReset.vue"),
                meta: {
                    title: "Passwort vergessen",
                },
            },
            {
                path: "/registrieren",
                name: "Register",
                component: () => import(/* webpackChunkName: "Register" */ "../views/Register.vue"),
                meta: {
                    title: "Registrieren",
                },
            },
            {
                path: "/mein-profil",
                name: "ProfileSettings",
                component: () => import(/* webpackChunkName: "ProfileSettings" */ "../views/ProfileSettings.vue"),
                meta: {
                    title: "Mein Profil",
                },
            },
            {
                path: "/aktivieren/:validationToken",
                name: "Activate",
                props: true,
                component: () => import(/* webpackChunkName: "PasswordReset" */ "../views/Activate.vue"),
                meta: {
                    title: "Account aktivieren",
                },
            },
            {
                path: "/:prefilledEmail?",
                name: "Login",
                props: true,
                component: Login,
                meta: {
                    title: "Anmelden",
                },
            },
        ],
    },
    {
        path: "*",
        name: "PageNotFound",
        component: () => import(/* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue"),
        meta: {
            title: "Diese Seite wurde nicht gefunden",
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
