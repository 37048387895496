const validateEmailRegex =
    /^[\w.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]$/;

export default {
    required(inputName) {
        return v => !!v || `${inputName} wird benötigt.`;
    },
    valid: {
        email: v => validateEmailRegex.test(v) || "Bitte gib eine gültige E-Mail-Adresse ein",
    },
};
