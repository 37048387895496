import NProgress from "nprogress";
import "./progressbar.sass";

export const cancelTopProgress = () => NProgress.done();

export default function initProgress(router) {
    router.afterEach(cancelTopProgress);
    router.beforeEach((to, from, next) => {
        NProgress.start();
        return next();
    });
}
