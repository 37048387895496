import Vue from "vue";
import vuetify from "./plugins/vuetify";

import router from "./router";

router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : "MoVe";
    next();
});

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./plugins/think.theme.sass";

Vue.config.productionTip = false;

import initProgress from "./plugins/progressbar.js";
initProgress(router);

new Vue({
    router,
    vuetify,
    render: h => h("router-view"),
}).$mount("#app");
