<template>
    <v-dialog :value="true" max-width="300" persistent>
        <v-card class="pa-4">
            <v-card-title>
                <h2>Wo geht's hin?</h2>
            </v-card-title>
            <v-card-text class="pt-4 pb-0">
                <v-list>
                    <v-list-item v-for="app in apps" :key="app.name" :href="app.url">
                        <v-list-item-title v-text="app.name" />
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        apps: {
            type: Array,
            required: true,
        },
    },

    methods: {},
};
</script>
